import { Col, Divider, Row, Typography } from 'antd'

import AdultLessonsSection from './AdultLessonsSection'
import ClubChampionshipsSection from './ClubChampionshipsSection'
import CourtBookingsSection from './CourtBookingsSection'
import DoublesLeagueSection from './DoublesLeagueSection'
import InterurbanSection from './InterurbanSection'
import LaddersSection from './LaddersSection'
import RegistrationSection from './RegistrationSection'
import Section from '../common/Section'
import StringingSection from './StringingSection'
import WelcomeSection from './WelcomeSection'

const colProps = {
  style: {
    padding: '1em',
  },
}

const { Paragraph, Title } = Typography

const HomePage = () => {
  return (
    <>
      <WelcomeSection />
      {/* <ClubChampionshipsSection /> */}
      {/* <Row>
        <Col {...colProps} xs={24}>
          <CourtBookingsSection />
        </Col>
        <Col {...colProps} xs={24}>
          <RegistrationSection />
        </Col>
      </Row>
      <Row>
        <Col {...colProps} xs={24} md={24} lg={12} xl={12} xxl={12}>
          <AdultLessonsSection />
        </Col>
        <Col {...colProps} xs={24} md={24} lg={12} xl={12} xxl={12}>
          <DoublesLeagueSection />
        </Col>
        <Col {...colProps} xs={24} md={24} lg={12} xl={12} xxl={12}>
          <LaddersSection />
        </Col>
        <Col {...colProps} xs={24} md={24} lg={12} xl={12} xxl={12}>
          <InterurbanSection />
        </Col>
      </Row>
      <Row>
        <Col {...colProps} xs={24}>
          <StringingSection />
        </Col>
      </Row>  */}
      <Row>
        <Col {...colProps} xs={24}>
          <Section title="Closed for the season" level={2}>
            <Paragraph>
              The club has closed for the season. Check back in March for information about the upcoming season and how
              to register.
            </Paragraph>
          </Section>
        </Col>
      </Row>
    </>
  )
}

export default HomePage
